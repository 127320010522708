































import { Component, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ButtonBase,
    SelectBase,
  },
})
export default class SchoolTestPage extends Vue {
  private schoolId = this.$route.params.id
  private termExamId = this.$route.params.subid
  private termId = this.$route.params.termId

  private isProcessing = false

  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: '学校登録', href: '/teacher/setting/school' },
    { text: '', href: '' },
    { text: 'ページ指定', active: true },
  ]

  private gradeOptionDatas: { text: string; value: number }[] = []

  private currentInputIndex = 1

  private rows: { grade: string; start: string; end: string }[] = []

  private gradeCode = ''

  // 変更予約モードの分岐フラグ
  private isReserveMode = false

  private addRow(): void {
    if (this.currentInputIndex === 5) return
    this.rows.push({ grade: '', start: '', end: '' })
    this.currentInputIndex++
  }

  private removeRow(index: number): void {
    this.rows.splice(index, 1)
    this.currentInputIndex--
  }

  private get errors(): { index: number; error: string }[] {
    const errors = [] as { index: number; error: string }[]
    const regex = /^[0-9]+$/
    for (let i = 0; i < this.rows.length; i++) {
      const row: { grade: string; start: string; end: string } = this.rows[i]
      if (row.start !== '' && row.end !== '') {
        if (!row.start.match(regex) || !row.end.match(regex)) {
          errors.push({ index: i, error: 'ページは半角数字で入力してください' })
        } else if (parseInt(row.start) < 1) {
          errors.push({ index: i, error: '開始ページは1以上を入力してください' })
        } else if (parseInt(row.start) > parseInt(row.end)) {
          errors.push({ index: i, error: '終了ページは開始ページより後のページを設定してください' })
        }
      }
    }
    return errors
  }

  private get colortype(): string {
    const permitted =
      this.rows.length > 0 &&
      this.rows.filter((row) => row.grade === '' || row.start === '' || row.end === '').length === 0 &&
      this.errors.length === 0

    return permitted ? 'blue' : 'pointer-events-none'
  }

  private get prevPage(): string {
    return `/teacher/setting/school-test/${this.schoolId}?termId=${this.termId}#${this.gradeCode}`
  }

  private async loadSchoolTestPage() {
    await Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.gradeOptionDatas = res.data.map((grade: { id: number; name: string }) => {
        return {
          text: grade.name,
          value: grade.id,
        }
      })
    })

    await Vue.prototype.$http.httpWithToken.get(`/term_exams/${this.termExamId}/setting`).then((res: any) => {
      this.breadcrumbs[this.breadcrumbs.length - 2].text = res.data.termExam.schoolName
      this.breadcrumbs[
        this.breadcrumbs.length - 2
      ].href = `/teacher/setting/school-test/${this.schoolId}?termId=${this.termId}#${res.data.termExam.gradeCode}`
      this.isReserveMode = res.data.isReserveMode
      this.gradeCode = res.data.termExam.gradeCode
      if (!res.data.settingIsItem && res.data.settings.length > 0) {
        this.rows = res.data.settings.map((setting: { gradeId: number; startPage: number; endPage: number }) => {
          return {
            grade: setting.gradeId.toString(),
            start: setting.startPage.toString(),
            end: setting.endPage.toString(),
          }
        })
        this.currentInputIndex = res.data.settings.length
      } else {
        this.rows = [{ grade: '', start: '', end: '' }]
        this.currentInputIndex = 1
      }
    })
  }

  private async createSchoolTestPage() {
    Vue.prototype.$loading.start()
    await this.postTermExamsSetting()
    Vue.prototype.$loading.complete()
  }

  private async postTermExamsSetting() {
    if (this.isProcessing) return
    this.isProcessing = true

    const settings = this.rows.map((row: { grade: string; start: string; end: string }) => {
      return {
        gradeId: parseInt(row.grade),
        startPage: parseInt(row.start),
        endPage: parseInt(row.end),
      }
    })

    const apiUrl = this.isReserveMode
      ? `/term_exams/reserve/${this.termExamId}/setting`
      : `/term_exams/${this.termExamId}/setting`

    await Vue.prototype.$http.httpWithToken
      .post(apiUrl, { is_item: false, settings: settings })
      .then(() => {
        alert('登録しました。一覧に戻ります')
      })
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert('該当する学習内容がありません。項目一覧から選択してください。')
        } else {
          alert('エラーが発生しました。一覧に戻ります')
        }
      })
      .finally(() => {
        this.$router.push({
          path: `/teacher/setting/school-test/${this.schoolId}`,
          query: { termId: this.termId.toString() },
          hash: this.gradeCode,
        })
        this.isProcessing = false
      })
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadSchoolTestPage()
    Vue.prototype.$loading.complete()
  }
}
